<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmUniversityCourseFormData && Object.keys(vmUniversityCourseFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validation_name" v-html="vmUniversityCourseFormData.org_name"></label><br>
                  <label v-html="vmUniversityCourseFormData.org_id"> </label>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_name">{{cvCourNameLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_name" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_curr">{{cvCourCurrLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_curr" type="text" class="form-control" placeholder="USD, INR, GBP, etc" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_tuition_min">{{cvCourTuitionMinLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_tuition_min" type="text" class="form-control" placeholder="USD 120,000, INR 150,000, etc" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_tuition_max">{{cvCourTuitionMaxLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_tuition_max" type="text" class="form-control" placeholder="USD 220,000, INR 250,000, etc" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_scholarship_min">{{cvCourScholarshipMinLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_scholarship_min" type="text" class="form-control" placeholder="USD 50,000, INR 70,000, etc" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_scholarship_max">{{cvCourScholarshipMaxLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_scholarship_max" type="text" class="form-control" placeholder="USD 20,000, INR 50,000, etc" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_adm_cycle">{{cvCourAdmCycleLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.cour_adm_cycle" type="text" class="form-control" placeholder='["January","September"]' required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcour_adm_cycle">{{cvCourAdmurlLabel}}</label>
                  <input v-model="vmUniversityCourseFormData.url" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationurl">{{cvCourUrl}}</label>
                  <input v-model="vmUniversityCourseFormData.url" type="text" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="organisationCourseEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { UniversityCourses } from "../../../../FackApi/api/universityCourse.js"
import ApiResponse from "../../../../Utils/apiResponse.js"
import userPermission from "../../../../Utils/user_permission.js"

export default {
  name: "UniversityCourseEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUniversityCourseObj: {
      type: Object,
      default: function () {
        return {
          "org_name": "",
          "org_id": "",
          "college_id": "",
          "cour_name": "",
          "cour_desc": "",
          "cour_curr": "",
          "cour_tuition_min": "",
          "cour_tuition_max": "",
          "cour_scholarship_min": "",
          "cour_scholarship_max": "",
          "cour_adm_cycle": "",
          "url": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "organisation_course_edit",
      cvCardTitle: "Edit University Course",
      cvCardSubHeader: "Edit University Course ",
      cvSubmitBtn: "Edit",
      cvUnivNameLabel: "University Name",
      cvUnivIdLabel: "University Id",
      cvCollegeIdLabel: "College Id",
      cvCourNameLabel: "Course Name",
      cvCourDescLabel: "Course Description",
      cvCourCurrLabel: "Currency",
      cvCourTuitionMinLabel: "Tuition Fees Min",
      cvCourTuitionMaxLabel: "Ruition Fees Max",
      cvCourScholarshipMinLabel: "Scholarship Min",
      cvCourScholarshipMaxLabel: "Scholarship Max",
      cvCourAdmCycleLabel: "Admission Cycle",
      cvCourAdmurlLabel: "Add University Course Link",
      cvCourUrl: "Course url",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "University Course",
      vmUniversityCourseFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.organisationCourseView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUniversityCourseFormData) {
          if (!this.vmUniversityCourseFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * organisationCourseView
     */
    async organisationCourseView () {
      try {
        if (this.propOpenedInModal) {
          this.vmUniversityCourseFormData = this.propUniversityCourseObj
        }
        else {
          let organisationCourseId = this.$route.params.cour_id
          let organisationCourseViewResp = await UniversityCourses.organisationCourseView(this, organisationCourseId)

          if (organisationCourseViewResp && organisationCourseViewResp.resp_status) {
            this.vmUniversityCourseFormData = organisationCourseViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseView() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseEdit
     */
    async organisationCourseEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let organisationCourseAddResp = await UniversityCourses.organisationCourseEdit(this, this.vmUniversityCourseFormData)
        await ApiResponse.responseMessageDisplay(this, organisationCourseAddResp)

        if (organisationCourseAddResp && !organisationCourseAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUniversityCourseEditModal", this.vmUniversityCourseFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
